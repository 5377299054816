@import 'styles/styles';

.section__top {
  display: flex;
  gap: 7px;
  flex-direction: column;
  margin-bottom: 16px;
}

.booking {
  box-shadow: $cardBoxShadow;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }

  .main {
    padding: 19px 25px;
    border-radius: 5px;

    @include mediaQuery(max, $lgTablet) {
      position: relative;
    }
  }

  z-index: 15;
  background-color: $white;
  width: 360px;

  &.modal__mode_on {
    box-shadow: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 0;
    padding-bottom: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 7;

    .main {
      box-shadow: none;
    }

    @include mediaQuery(max, $lgTablet) {
      display: block;
    }
  }
}

.booking__quote_incomplete {
  .avg__price {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  .main {
    background: linear-gradient(to bottom, #ddf0f280 120px, $white 0);
  }
}

.booking__quote_loading {
  .main {
    background: linear-gradient(to bottom, #ddf0f280 160px, $white 0);
  }
}

.booking__reviews_none {
  .main {
    background: linear-gradient(to bottom, #ddf0f280 90px, $white 0);
  }
}

.booking__quote_complete {
  .main {
    background: linear-gradient(to bottom, #ddf0f280 160px, $white 0);
  }

  &.booking__reviews_none {
    .main {
      background: linear-gradient(to bottom, #ddf0f280 135px, $white 0);
    }
  }
}

.booking__quote_no_dates {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.bookingQuestion {
  @extend .booking;

  @include mediaQuery(max, $lgTablet) {
    .cancellationPolicy {
      margin: 0 24px;
    }
  }

  @include mediaQuery(max, $lgTablet) {
    .main {
      box-shadow: none;
    }
  }

  @include mediaQuery(max, $lgTablet) {
    box-shadow: none;
    position: relative;
  }
}

.bookingTop {
  @include mediaQuery(max, $lgTablet) {
    display: none;

    .modal__mode_on & {
      display: block;
    }
  }
}

.bookingTopQuestion {
  background-color: #ddf0f2;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 24px 24px 18px;

  @include mediaQuery(max, $lgTablet) {
    padding: 18px 24px;
  }
}

.bookingPrice {
  display: block;
  font-family: $font-tertiary;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  min-height: 28px;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }

  b {
    font-weight: 700;
  }
}

.resultDetailRating {
  display: flex;
  margin-bottom: 0.75rem;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }

  span {
    font-family: $font-tertiary;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
}

.starRating {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-right: 5px;

  span {
    display: block;
    font-family: $font-tertiary;
    font-weight: 600;
    margin-right: 4px;
  }
}

.bookingFilter {
  margin-bottom: 13px;
}

.bookingDates {
  > div {
    max-width: 100% !important;
  }
}

.link {
  border: none !important;

  @include mediaQuery(max, $lgTablet) {
    display: none;

    &:global(.btn-secondary) {
      display: none;
    }

    .modal__mode_on & {
      display: block;
    }
  }
}

.reviews {
  background-color: transparent;
  border: none;
  color: $grey-dot-active;
  cursor: pointer;
  font-family: $font-tertiary;
  font-weight: 600;
  padding: 0;
  text-decoration: underline;
}

.booknow__section {
  margin-bottom: 12px;

  .booknow__btn {
    width: 100%;

    &:disabled {
      cursor: text;
    }

    border: none;
    margin-bottom: 6px;
  }

  span {
    display: block;
    font-weight: 600;
    text-align: center;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
  }
}

.viewDetails {
  background-color: transparent;
  border: none;
  color: #14a1b2;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;
  top: -0.25rem;

  @include mediaQuery(min, $lgTablet) {
    display: none;
  }
}

.modal__btn_close {
  background-color: transparent;
  border: none;
  margin: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0;

  svg {
    width: 16px;
  }
}

.identifier {
  text-align: center;
  padding: 12px 0;

  @include mediaQuery(max, $lgTablet) {
    display: none;

    .modal__mode_on & {
      display: block;
    }
  }
}

.identifierLead {
  font-weight: 600;
}

.creditLink {
  width: 100%;
  padding: 10px 24px;
  margin-top: 0.5rem;
  color: #1ab2c5;
  text-align: center;
  text-decoration: underline;
  display: inline-block;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}

.error__message {
  color: $capeCoral;
  font-size: 14px;
  display: block;
  padding-bottom: 18px;
}
