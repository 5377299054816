@import 'styles/styles';

.list {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
}

.item {
  list-style: none;
  outline-color: $maximumPurple;

  &:first-child {
    .itemRow {
      height: 152px;
    }

    .itemColumnA {
      bottom: unset;
    }
  }
}

p:not(.elementor-widget-text-editor > p) {
  margin: 0;

  &.itemDate {
    text-align: right;
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    width: 250px;
  }

  &.itemMessage {
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
  }
}

.itemMessage {
  text-align: center;
}

.itemRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 8px;
  height: 128px;
}

.itemColumnA {
  width: 142px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: calc(24px / 2);

  &:before {
    content: '';
    height: 2px;
    background-color: $maximumPurple;
    width: 24px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.itemColumnACash {
  border: 2px solid $maximumPurple;

  &:before {
    background-color: $maximumPurple;
  }
}

.itemColumnACredit {
  border: 2px solid $evolveBlue;

  &:before {
    background-color: $evolveBlue;
  }
}

.itemColumnANone {
  border: 2px solid $saldova;

  &:before {
    background-color: $saldova;
  }
}

.itemColumnB {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.dateLayout {
  position: relative;
}

.itemDate {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
}

.itemLine {
  height: calc(100% - 24px);
  width: 4px;
  border-radius: 4px;
  background-color: $saldova;
}

.itemLineCash {
  background-color: $maximumPurple;
}

.itemLineCredit {
  background-color: $evolveBlue;
}

.itemLineNone {
  background-color: $saldova;
}

.itemCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $white;
  position: relative;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $saldova;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
