@import 'styles/styles';

.article {
  display: flex;
  flex-flow: column;
  gap: 16px 0;

  p:not(.elementor-widget-text-editor > p) {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }

  span {
    font-weight: 700;
  }
}
