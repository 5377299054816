@import 'styles/styles';

.main {
  display: flex;
  gap: 5px;

  span {
    line-height: 21px;
    font-weight: $font-bold;
  }
  .reviews {
    font-weight: $font-bold;
  }

  button {
    background-color: transparent;
    border: none;
    color: $grey-dot-active;
    cursor: pointer;
    font-family: $font-tertiary;
    font-weight: 600;
    padding: 0;
  }
}
