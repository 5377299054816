@import 'styles/styles';

.section {
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;

  .title {
    font-size: 22px;
    line-height: 30px;
    width: 100%;

    @include mediaQuery(min, $smDesktop) {
      width: 100%;
    }
  }

  .description {
    @include mediaQuery(min, $smDesktop) {
      max-width: 385px;
    }

    span {
      font-weight: 700;
    }
  }

  &.noDates {
    .columnA {
      width: 100%;

      @include mediaQuery(min, smDesktop) {
        padding: 0 16px 0 0;
      }
    }

    .description {
      max-width: 100%;
    }
  }

  .columnA {
    width: 100%;

    @include mediaQuery(min, $smTablet) {
      width: 385px;
    }

    @include mediaQuery(min, $smDesktop) {
      width: 100%;
    }

    @include mediaQuery(min, $mdDesktop) {
      width: 385px;
    }

    p:not(.elementor-widget-text-editor > p).description {
      margin: 0 0 24px;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .columnB {
    margin: 0 16px 0 auto;
    display: block;

    @include mediaQuery(min, $smallMobile) {
      margin: 0 auto;
    }

    @include mediaQuery(min, $smTablet) {
      margin: 0 0 0 auto;
      width: calc(100% - 385px);
    }

    @include mediaQuery(min, $smDesktop) {
      margin: 0 auto;
    }

    @include mediaQuery(min, $mdDesktop) {
      width: calc(100% - 385px);
    }
  }
}

.modalSection {
  max-height: 600px;
  width: 270px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-light;
    border-radius: 10px;
  }

  .columnA,
  .columnB {
    width: 100%;
  }

  .columnB {
    margin: 0 0 0 auto;
  }

  @include mediaQuery(min, $smallMobile) {
    width: 100%;

    &:not(.modalSectionWithRefunds) {
      max-width: 385px;

      .columnA {
        width: auto;
      }
    }
  }

  @include mediaQuery(min, $smTablet) {
    max-height: initial;

    .columnA {
      width: 100%;
    }

    .columnB {
      margin: 0 auto;
      width: auto;
    }
  }

  @include mediaQuery(min, $smDesktop) {
    .columnA {
      width: calc(100% - 300px);
    }

    .columnB {
      width: 300px;
    }
  }
}
