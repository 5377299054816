@import 'styles/styles';

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba($black, 0.28);
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: none;
}

.open {
  display: flex;

  .content {
    display: block;
  }
}

.content {
  display: none;
  background-color: $white;
  border-radius: 6px;
  padding: 24px 16px;
  max-width: 350px;
  position: relative;

  @include mediaQuery(min, $smTablet) {
    max-width: 480px;
  }

  @include mediaQuery(min, $smDesktop) {
    max-width: 768px;
    padding: 28px 32px;
  }
}

.button {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 18px;
  top: 18px;

  @include mediaQuery(min, $smDesktop) {
    right: 20px;
    top: 20px;
  }
}
