@import 'styles/styles';

.main {
  .price__breakdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.order__item {
  font-size: 14px;
  line-height: 17px;
  padding: 6px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-top: 12px;
    padding: 12px 0;
  }

  &.order__item_expanded {
    padding-bottom: 0;
    .caret {
      transform: rotate(-180deg);
    }
  }

  .order__detail_panel {
    margin-top: 10px;
    font-weight: 500;
    background-color: #ddf0f280;
    padding: 13px;
    margin-bottom: 10px;
    .order__row {
      padding: 6px 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  button {
    font-size: inherit;
    align-items: center;
    background-color: transparent;
    border: none;
    line-height: 17px;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  .order__row_btn {
    color: $blue-dark;
    display: flex;

    svg {
      margin-left: 6px;
    }
  }
}

.order__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  gap: 5px;

  abbr {
    display: none;
  }

  svg {
    cursor: pointer;
    margin-left: 0.5rem;
    position: relative;
    top: 3px;
  }

  .caret {
    transition: all ease-in 0.2s;
    top: 1px;
    use {
      fill: $saldova;
    }
  }
  strong {
    @include mediaQuery(min, $mobile) {
      font-weight: normal;
    }
  }
}

.price {
  &__negative {
    color: $capeCoral;
  }
}

.discount {
  color: $gatlinburg;
}

.discount__remove_btn {
  color: $evolveBlue;
  cursor: pointer;
}

.tooltip {
  background-color: $white !important;
  box-shadow: 0 2px 10px 0 rgba(9, 63, 70, 0.2);
  color: $casal !important;
  font-family: $font-primary !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  max-width: 16rem;
  opacity: 1 !important;

  &.place-top {
    &:after {
      border-top-color: $white !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}

.bar__loader {
  padding-bottom: 1rem;
}

.total {
  border-top: 1px solid $gainsboro;
  padding-top: 16px;

  span {
    font-weight: 700;
  }
}
