@import 'styles/styles';

.wrapper {
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.borderTop {
  border-top: 1px solid $grey-light;
}

.borderBottom {
  border-bottom: 1px solid $grey-light;
}

.section {
  display: flex;
  flex-direction: column;
  margin: 16px 2px;
  gap: 2px 0;

  @include mediaQuery(min, $lgTablet) {
    margin: 16px 2px 0;
    gap: 4px 0;
  }
}

.title,
p:not(.elementor-widget-text-editor > p).description,
.button {
  font-size: 14px;
  line-height: 23px;

  @include mediaQuery(min, $lgTablet) {
    font-size: 14px;
  }
}

.description {
  padding: 0;
  margin: 0;
  max-width: 250px;
}

.button {
  color: $evolveBlue;
  font-weight: 700;
  padding: 0;
  background-color: transparent;
  border: 0;
  text-align: left;
  margin: 2px 0 0;
}
