@import 'styles/styles';

.main {
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 24px;
  font-weight: $font-bolder;
  line-height: 29px;
  letter-spacing: 0px;
}

.included {
  font-size: 12px;
  font-weight: $font-bold;
  line-height: 17px;
}
