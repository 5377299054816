@import 'styles/styles';

.main {
  margin-bottom: 12px;
}

.row {
  font-size: 14px;
  line-height: 17px;
  padding: 4.5px 0;
}
