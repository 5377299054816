.spinner {
  position: relative;
  top: -8px;
}

.btntext {
  display: inline !important;
}

.btntext-icon {
  @extend .btntext;
  position: relative;
  top: -15px;
}
